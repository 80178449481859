.login {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 10rem;
}

.title {
  margin: 0 auto;
  margin-top: 1rem;
  padding: 0;
  color: gray;
}

.login_buttons {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 4rem;
  width: 15rem;
}

.login_buttons button {
  margin-top: 2rem;
  height: 2rem;
  cursor: pointer;
  border-radius: 0.2rem;
  color: #ffffff;
  border-color: #4078c0;
  background-color: #4078c0;
}

.login_buttons button:hover {
  border-color: #2e78d8;
  background-color: #2e78d8;
}
