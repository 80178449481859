
.table {
  display: flex;
  flex-direction: column;
  color: #ECB743;
  width: fit-content;
  margin: 0 auto;
  border: 1px solid #ECB743;
  margin-top: 1rem;
}

.reports {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-top: 3rem;
  color: black;
  background-color: #3A220B;
  gap: 0.5rem
}

.reported{
  display: flex;
  flex-direction: column;
  width: 40%;
  margin: 0 auto;
  margin-top: 3rem;
  color: black;
}


.theader {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 2rem;
  margin: 0 auto;
  background-color: #3A220B;
}

.theader span {
  margin: 0 auto;
  margin-top: 0.5rem;
  padding: 0;
  color: #ECB743;
}

.content {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  background-color: #ECB743;
}

.leftdata, .rightdata {
  display: flex;
  flex-direction: column;
  background-color: #3A220B;
}

.leftdata h4, .rightdata h4 {
  margin-block-start: 0em;
  margin-block-end: 0em;
  width: 90%;
  margin: 0 auto;
  text-align: center;
}

.userinfo {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  color: #ECB743;
}

.rows {
  display: flex;
  flex-direction: column;
}

.rows span {
  margin: 1.3rem 0 1.3rem 0;
}

.coffee {
  display: flex;
  
}

.coffee input {
  margin: 1rem;
  height: 1.6rem;
}

.managebutton {
  height: 2rem;
  margin: 1rem 0 1rem 0;
  cursor: pointer;
  color: #3A220B;
  border: 1px solid #ECB743;
  background-color: #ECB743;
}

.managebutton:hover {
  background-color: #3A220B;
  color: #ECB743;
}

.rheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 2rem;
  width: 100%;
  margin: 0 auto;
  background-color: #ECB743;
}

.rheader span {
  flex: 1;
  margin: 0 auto;
  padding: 0;
  color: #3A220B;
}

.reportcard {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  border: 1px solid #ECB743;
  width: 80%;
  margin: 0 auto;

}

.rdata{
  font-size: large;
  color: black;
}
.reportcard p {
  margin: 0.5rem;
}

.reportcard .clickable {
  cursor: pointer;
}

.clickable:hover {
  color: black;
}

.close {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  background-color: #3A220B;
}

.close button{
  background-color: red;
  border: none;
  margin: 0.2rem;
  padding: 0.5rem;
  border-radius: 10px;
}

.close button:hover{
  cursor: pointer;
  border-color: #c03207;
  background-color: #c03207;
}

@media screen and (max-width:950px) {
  .content {
    display: flex;
    flex-direction: column;
    font-size: 0.8rem;
    align-items: center;
  }

  .rheader {
    font-size: 0.8rem;
  }

  .coffee input {
    font-size: 0.8rem;
  }
}