.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-top: 2rem;
  background-color: #3A220B;
}

.userContainer {
  display: flex;
  justify-content: space-around;
  width: 30rem;
    margin: 0.5rem;
    padding: 0.5rem;
    border: 1px solid grey;
    color: #3A220B;
    background-color: #ECB743;
    cursor: pointer;
}

.userContainer:hover {
  color: #ECB743;
  border: 1px solid black;
  background-color: #3A220B;
}
