.navbar {
  width: 100%;
  color: #ffffff;
  background-color: #ECB743;
  border-bottom: 2px solid black;
  font-size: 1.2rem;
  z-index: 2;
}

.content {
  display: flex;
  justify-content: space-around;
  align-items: center
}

.home {
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;
  color: #3A220B;
  font-size: 1.3rem;
}

.navigate ul{
  display: flex;
  padding-inline-start: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}

.navigate li {
  list-style: none;
  color: #3A220B;
  margin-left: 1rem;
  margin-right: 1rem;
  cursor: pointer;
  padding: 1rem;
}

.navigate a{
  text-decoration: none;
}

.navigate li:hover, .navigate li:hover a{ 
  background-color: #3A220B;
  color: #ECB743;
}


.options {
  display: flex;
  justify-content: space-between;
  align-items: center
}

.active_user {
  font-size: 0.8rem;
  color: #3A220B;
}

.logout {
  border-radius: 0.2rem;
  cursor: pointer;
  color: #ffffff;
  border-style: solid;
  border-color: #3A220B;
  background-color: #3A220B;
  margin-left: 2rem;
  font-size: 0.8rem;
  height: fit-content;
}

.logout:hover {
  border-color: #c03207;
  background-color: #c03207;
}

@media screen and (max-width: 950px){

  .content {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 0.8rem;
  }
}