.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 1rem;
    background-color: #3A220B;
    color: #ECB743;
}

.inputs {
    margin: 0.5rem;
    width: 12rem;
}

.inputs input {
    padding: 0.25rem;
}

.inputs button {
  width: 8rem;
  height: 2rem;
  margin-left: 1rem;
  cursor: pointer;
  color: #3A220B;
  border-style: solid;
  border-color: black;
  background-color: #ECB743;
}

.inputs button:hover {
  background-color: #3A220B;
  color: #ECB743;
  border: 1px solid #ECB743;
}