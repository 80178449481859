.main {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background-color: #3A220B;
}

.title {
  margin: 0 auto;
  margin-bottom: 1rem;
  color: black;
}
