.containerSearch {
 padding-top: 2rem;
 background-color: #3A220B;
}

.container {
  display: flex;
  width: 50%;
  margin: 0 auto;
  justify-content: center;
  color: #ffffff;
}

.searchbox {
  width: 60%;
}

.search {
  width: 100%;
  height: 1.6rem;
}

.search_button {
  width: 8rem;
  height: 2rem;
  margin-left: 1rem;
  cursor: pointer;
  color: #3A220B;
  border-style: solid;
  border: 1px solid #3A220B;
  background-color: #ECB743;
}

.search_button:hover {
  background-color: #3A220B;
  color: #ECB743;
  border: 1px solid #ECB743;
}

.search_button_reports {
  width: 8rem;
  height: 2rem;
  margin: 0;
  cursor: pointer;
  color: #ffffff;
  border-style: solid;
  border-color: red;
  background-color: red;
}

.title {
  margin-block-start: 0;
  padding-top: 1rem;
  text-align: center;
  color: #ECB743;
}

@media screen and (max-width: 950px) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .search {
    font-size: 0.7rem;
  }

  .search_button {
    margin-top: 1rem;
  }

}