.container {
    display: flex;
    justify-content: center;
    background-color: #3A220B;
    padding: 1rem;
    color: aliceblue;
}

.king {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 1rem;
    background-color: #ECB743;
    margin: 1rem;
}

.king li {
    padding: 0.2rem;
}

.tittle {
    display: block;
    font-size: 1.5em;
    color: #ECB743;
    background-color: #3A220B;
    text-align: center;
}

.kingSection{
    margin: 0.5rem;
    padding: 0.5rem;
    background-color: #3A220B;
}

