.livestats {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: stretch;
  gap: 2rem;
}

.container {
  font-size: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 1rem;
  background-color: #3a220b;
  color: #ecb743;
}

.headerBigNumber {
  font-size: 10rem;
  position: relative;
}

.label {
  font-size: 1.6rem;
  margin: 0.4rem;
}

.last24 {
  font-size: 1.1rem;
  position: absolute;
  right: 2rem;
  bottom: 1rem;
}
