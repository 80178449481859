.container{
    display: flex;
    background-color: #3A220B;
    height: 88vh;
}

.ticketsContainer{
    margin: 1rem;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    width: 20%;
    border: 1px solid #ECB743;
    background-color: #ECB743;
    border: 2px solid #3A220B;
    height: 92%;
    overflow-y: scroll;
    padding: 0.5rem;
}

.ticket {
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    border-top: 1px solid #3A220B;
    position: relative;
}

.ticketActive {
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    border-bottom: 1px solid #3A220B;
    background-color: #3A220B;
    color: #ECB743;
}

.ticket:hover {
    color: #ECB743;
    background-color: #3A220B;
}

.ticketNumber{
    font-size: 1.2rem;
    font-weight: bold;
}

.ticketInfo {
    padding-left: 0.5rem;
}

.chatContainer {
    margin: 2rem;
    width: 80%;
    height: 95%;
    background-color: #3A220B;
    display: flex;
    border: 1px solid #ECB743;
    flex-direction: column;
    overflow-y: scroll;
}

::-webkit-scrollbar {
    display: none;
}

.admin {
    display: flex;
    justify-content: flex-end;
    text-align: end;
}

.messages {
    font-size: 1.1rem;
    width: fit-content;
    margin: 0.25rem 1rem 3rem 1rem;
    background-color: #ECB743;
    border-radius: 0.5rem;
    padding: 0.5rem;
    position: relative;
}

.inputMessage {
    width: 80%;
    background-color: #ECB743;
    border-radius: 0.3rem;
    border: 1px solid #3A220B;
    padding: 0.5rem;
}

.buttonSend {
    background-color: #ECB743;
    border: 1px solid black;
    margin-left: 1rem;
    padding: 0.3rem;
    border-radius: 0.5rem;
    cursor: pointer;
    font-size: 0.7rem;
    color: #3A220B;
}

.buttonClose {
    border: 1px solid black;
    margin-left: 1rem;
    padding: 0.3rem;
    border-radius: 0.5rem;
    cursor: pointer;
    font-size: 0.7rem;
    background-color: red;
    color: white;
}

.buttonClose:hover {
    border-color: #c03207;
  background-color: #c03207;
}

.buttonSend:hover {
    background-color: #3A220B;
    border: 1px solid #ECB743;
    color: #ECB743;
}

.buttonsTickets {
    display: flex;
    justify-content: space-around;
    padding: 0.5rem;
}

.active {
    background-color: #3A220B;
    color: #ECB743;
    border: none;
    padding: 0.3rem;
    border-radius: 0.25rem;
    font-size: 1.1rem;
}

.noActive {
    background-color: #ECB743;
    border: 1px solid #3A220B;
    border-radius: 0.25rem;
    padding: 0.3rem;
    font-size: 1.1rem;
}

.noActive:hover {
    background-color: #3A220B;
    color: #ECB743;
    cursor: pointer;
}
.messageArea {
    display: flex;
    justify-content: center;
    position: fixed;
    height: auto;
    bottom: 1rem;
    width: 70%;
}

.deleteMessage {
    display: none;
    position: absolute;
    top: 0.1rem;
    right: 0.2rem;
    border: 1px solid black;
    margin-left: 1rem;
    padding: 0.3rem;
    border-radius: 0.5rem;
    cursor: pointer;
    font-size: 0.7rem;
    background-color: red;
    color: white;
}

.messages:hover .deleteMessage {
    display: block;
}

.deleteMessageAdmin{
    display: none;
    position: absolute;
    top: 0.1rem;
    left: -0.8rem;
    border: 1px solid black;
    margin-left: 1rem;
    padding: 0.3rem;
    border-radius: 0.5rem;
    cursor: pointer;
    font-size: 0.7rem;
    background-color: red;
    color: white;
}

.messages:hover .deleteMessageAdmin {
    display: block;
}

.newMessage{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: green;
    position: absolute;
    right: 0.1rem;
    border: 1px solid #3A220B;
}